import { useTranslation } from 'lib/hooks/useTranslation';
import Image from 'next/image';
import image404 from 'public/images/404.svg';
import style from './Styles.module.scss';

const NotFoundPage = () => {
    return (
        <div className={style.wrapper}>
            <div className={style.content}>
                <Image src={image404} alt="Page Not Found" />
                <h2 className={style.text404}>{useTranslation().notFound.notfound}</h2>
            </div>
        </div>
    );
};

export default NotFoundPage;
